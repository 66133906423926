import React, { useEffect, useState } from 'react';
import './registerform.css';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { apiurl } from '../../urls/url';

export default function Confirmpasswordform() {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [searchParams] = useSearchParams();
    const otp = searchParams.get('otp');
    const uidb64 = searchParams.get('uidb64');
    const reset_token = searchParams.get('reset_token');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    };

    const toggleConfirmPasswordVisibility = () => {
        setIsConfirmPasswordVisible(prevState => !prevState);
    };

    const handlePasswordSubmit = () => {
        console.log('submit handle working')
        if (password !== confirmPassword) {
            console.log("Password Does Not Match");
        } else {

            console.log("otp ======", otp);
            console.log("uidb64 ======", uidb64);
            console.log("reset_token ======", reset_token);
            console.log("password ======", password);

            const formdata = new FormData()

            formdata.append("otp", otp)
            formdata.append("uidb64", uidb64)
            formdata.append("reset_token", reset_token)
            formdata.append("password", password)

            try {
                axios.post(`${apiurl}/user/password-change/`, formdata).then((res) => {
                    console.log(res.data.code);
                })
            } catch (error) {
                console.log(error);
            }
        }
    }


    return (
        <div className='registration-form '>
            <h4 className='mb-4 text-center'>Confirm Your Password</h4>
            <form>
                <div className='passwordinput-div'>
                    <input
                        type={isPasswordVisible ? 'text' : 'password'}
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Enter your password"
                    // style={{ paddingRight: '2rem' }}
                    />
                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                    >
                        <i className={`fas ${isPasswordVisible  ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                    </button>
                </div>
                <div className='passwordinput-div'>
                    <input
                        type={isConfirmPasswordVisible ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder="Confirm your password"
                    // style={{ paddingRight: '2rem' }}
                    />
                    <button
                        type="button"
                        onClick={toggleConfirmPasswordVisibility}
                    >
                        <i className={`fas ${isConfirmPasswordVisible  ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                    </button>
                </div>
                <div className='text-center'>
                    <input className='submitbutton' type="button" value='Verify' onClick={handlePasswordSubmit} />
                </div>
            </form>
        </div>
    )
}
