import React, { useEffect, useState } from 'react';
import './useradmin.css';
import { Tabs, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiurl } from '../../urls/url';
import { jwtDecode } from 'jwt-decode';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';

export default function Useradmin() {

    const [show, setShow] = useState(false);
    const [key, setKey] = useState('Orders');
    const navigate = useNavigate();
    const [userordersdata, setuserordersdata] = useState();
    const [singleorderdata, setsingleorderdata] = useState();
    const [showAnotherModal, setShowAnotherModal] = useState(false);
    const [userprofile, setuserprofile] = useState();
    const [userprofilename, setuserprofilename] = useState();
    const [userprofileemail, setuserprofileemail] = useState();
    const [userprofileno, setuserprofileno] = useState();
    const userid = localStorage.getItem('token')
    let user_id;

    if (userid) {
        user_id = jwtDecode(userid)
    }

    useEffect(() => {
        userorders()
        userwishlist()
        userprofileinfo()
    }, [])

    const userorders = () => {
        axios.post(`${apiurl}/customer/orders/${user_id.user_id}`)
            .then((res) => {
                console.log(res.data, 'user orders are here')
                setuserordersdata(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const userwishlist = async () => {
        axios.post(`${apiurl}/customer/wishlist/${user_id.user_id}`)
            .then((res) => {
                console.log(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const orderidhandle = (oid) => {
        handleShow()
        axios.post(`${apiurl}/customer/order/detail/${user_id.user_id}/${oid}`)
            .then((res) => {
                console.log(res.data);
                setsingleorderdata(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const userprofileinfo = () => {
        axios.get(`${apiurl}/customer/setting/${user_id.user_id}`)
            .then((res) => {
                console.log(res)
                setuserprofile(res.data)
                setuserprofilename(res.data.full_name)
                setuserprofileno(res.data.user.phone)
                setuserprofileemail(res.data.user.email)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const userprofileinfoedit = async () => {
        try {
            const response = await axios.patch(`${apiurl}/customer/setting/${user_id.user_id}`, {
                'id': user_id.user_id,
                'full_name': userprofilename,
                'email': userprofileemail,
                'phone': userprofileno
            });
            console.log(response.data);
            toast.success('profile chnaged succesfully')
            setuserprofile(response.data)
            handleAnotherModalClose()
          } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('got an error please check infomation and fill details again')
          }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleAnotherModalClose = () => setShowAnotherModal(false);
    const handleAnotherModalShow = () => setShowAnotherModal(true);

    const logout = () => {
        console.log('logout function is working')
        localStorage.removeItem('token')
        navigate('/')
        window.location.reload();
        toast.success('Logut Successfully')
    }


    return (
        <>
            <div className="useradmin">
                <div className='container'>
                    <div className="row">
                        <div className="col-sm-4 mb-3">
                            <div className='userprofile-left'>
                                <div className='userprofile p-2'>
                                    <h6>My profile</h6>
                                    <div className='userprofile-main'>
                                        <h2>{userprofile?.full_name}</h2>
                                    </div>
                                </div>
                                <Tabs
                                    id="vertical-tabs"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="flex-column"
                                >
                                    <Tab eventKey="Orders" title={<><i className="fa-solid fa-box"></i> My Orders </>}>
                                    </Tab>
                                    {/* <Tab eventKey="address" title={<><i class="fa-solid fa-map-location"></i> My address </>}>
                                    </Tab> */}
                                    <Tab eventKey="profile" title={<><i class="fa-solid fa-user"></i> My profile </>}>
                                    </Tab>
                                </Tabs>
                                <Button variant="link" className="w-100 text-start text-dark text-decoration-none px-3" onClick={logout}>
                                    <i className="fa-solid fa-right-from-bracket pe-4"></i> Logout
                                </Button>
                            </div>
                        </div>
                        <div className="col-sm-8 mb-4">
                            <div className='user-orderinfo-main'>
                                {key === 'Orders' && (
                                    <div className='user-orderinfo'>
                                        <h6>ALL ORDERS</h6>
                                        <table>
                                            <tr>
                                                <th>#</th>
                                                <th>Order Id</th>
                                                <th>User E-mail</th>
                                                <th>Coupon</th>
                                                <th>Price</th>
                                                <th>Gst %</th>
                                                <th>Total</th>
                                            </tr>
                                            {
                                                userordersdata?.map((value, index) => {
                                                    // console.log(value.orderitem[0]?.product)
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td onClick={() => orderidhandle(value.oid)}>
                                                                <a href='#'>
                                                                    {value.oid}
                                                                </a>
                                                            </td>
                                                            <td>{value.email}</td>
                                                            <td>{
                                                                value.coupons.length === 0 ? "No Coupon" : value.coupon
                                                            }</td>
                                                            <td>{value.sub_total}</td>
                                                            <td>{value.tax_fee}</td>
                                                            <td>{value.total}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </div>
                                )}
                                {/* {key === 'address' && (
                                    <div className='user-addressinfo'>
                                        <button className='btn btn-success'><i class="fa-solid fa-plus"></i> Add Address</button>
                                        <div className='user-addres-main'>
                                            <div className='user-address'>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <i class="fa-solid fa-house me-2"></i>
                                                            Default Address
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>+91 9876543210</p>
                                                            <p>3-15/10/403 Newark, Street no 5, Next To Pizza Hut, Bangalore, Karnataka, 560003, India.</p>
                                                            <p>fazilnoor@gmail.com</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='user-editinfo'>
                                                            <button className='btn btn-edit'>
                                                                <i class="fa-solid fa-pen-to-square"></i>
                                                            </button>
                                                            <button className='btn btn-delete'>
                                                                <i class="fa-solid fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className='user-address'>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <i class="fa-solid fa-house me-2"></i>
                                                            Default Address
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p>+91 9876543210</p>
                                                            <p>3-15/10/403 Newark, Street no 5, Next To Pizza Hut, Bangalore, Karnataka, 560003, India.</p>
                                                            <p>fazilnoor@gmail.com</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='user-editinfo'>
                                                            <button className='btn btn-edit'>
                                                                <i class="fa-solid fa-pen-to-square"></i>
                                                            </button>
                                                            <button className='btn btn-delete'>
                                                                <i class="fa-solid fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )} */}
                                {key === 'profile' && (
                                    <div className='user-profile-main'>
                                        <h6 className='user-profile-editbtn' onClick={handleAnotherModalShow}>Edit Profile <i class="fa-regular fa-pen-to-square"></i></h6>
                                        <div class="edit-userprofile-main">
                                            <h4>{userprofile.full_name}</h4>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-6'>
                                                <p>User E-mail :- {userprofile.user.email}</p>
                                            </div>
                                            <div className='col-6'>
                                                <p>User Number :- {userprofile.user.phone}</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <Link to="/forgotpassword">
                                                    <p>Change Password</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} size="lg" onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Order Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='user-orderinfo-main'>
                        <div className='user-orderinfo'>
                            <h6>Wishlist</h6>
                            <table>
                                <tr>
                                    <th>#</th>
                                    <th>Photos</th>
                                    <th>Product</th>
                                    <th>QTY</th>
                                    <th>Shippping</th>
                                    <th>Price</th>
                                    <th>Gst%</th>
                                    <th>Total</th>
                                </tr>
                                {
                                    singleorderdata?.orderitem.map((value, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td><img src={`${value.product.image}`} className='img-fluid' /></td>
                                                <td className='productdisc'>{value.product.title}</td>
                                                <td>{value.qty}</td>
                                                <td>{value.shipping_amount}</td>
                                                <td>{value.sub_total}</td>
                                                <td>{value.tax_fee}</td>
                                                <td>{value.total}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <th colspan="2">Total Order</th>
                                    <td className='productdisc'></td>
                                    <td></td>
                                    <td>{singleorderdata?.shipping_amount}</td>
                                    <td>{singleorderdata?.sub_total}</td>
                                    <td>{singleorderdata?.tax_fee}</td>
                                    <td>{singleorderdata?.total}</td>
                                </tr>
                            </table>
                        </div>
                        <div className='user-singleorder-infomain'>
                            <div className='user-addressinfo'>
                                <div className='user-addres-main'>
                                    <div className='user-address w-100 me-0'>
                                        <table className='w-100'>
                                            <tr>
                                                <td>
                                                    <i class="fa-solid fa-house me-2"></i>
                                                    Delivery Address
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>{singleorderdata?.mobile}</p>
                                                    <p>{singleorderdata?.billing_address} {singleorderdata?.billing_city} {singleorderdata?.billing_state} {singleorderdata?.billing_country}</p>
                                                    <p>{singleorderdata?.email}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* User profile edit Modal */}
            <Modal className='profileedit-model' show={showAnotherModal} onHide={handleAnotherModalClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Your Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <input type='text' placeholder='Full Name' value={userprofilename} onChange={(e) => setuserprofilename(e.target.value)} />
                        </div>
                        {/* <div className="col-6">
                            <input type='email' placeholder='E-mail' value={userprofileemail} onChange={(e) => setuserprofileemail(e.target.value)}/>
                        </div> */}
                    </div>
                    {/* <div className="col-12">
                        <input type='number' placeholder='Phone No.' value={userprofileno} onChange={(e) => setuserprofileno(e.target.value)}/>
                    </div> */}
                    <input type='submit' value='Submit' onClick={userprofileinfoedit}/>
                    <ToastContainer />
                </Modal.Body>
            </Modal>
        </>
    );
}