import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './header.css'
import Cart from '../../pages/cart/cart';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { Cookies } from 'react-cookie';

export default function Header() {

    const [scrolled, setScrolled] = useState(false);
    const userid = localStorage.getItem('token')
    const [userlogin, setuserlogin] = useState();

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 200) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    let headerClasses = ['navbar', 'navbar-expand-lg', 'fixed-top'];
    if (scrolled) {
        headerClasses.push('scrolled');
    }

    const headerRef = useRef(null);
    const headeroverlayRef = useRef(null)

    const hidemenu = () => {

        if (headerRef.current.classList.contains('sidemenu-header-hide')) {
            headerRef.current.classList.remove('sidemenu-header-hide')
        } else {
            headerRef.current.classList.add('sidemenu-header-hide')
        }

        if (headeroverlayRef.current.classList.contains('header-overlay-hide')) {
            headeroverlayRef.current.classList.remove('header-overlay-hide')
        } else {
            headeroverlayRef.current.classList.add('header-overlay-hide')
        }
    }

    const logout = () => {
        const cookies = new Cookies();

        console.log('logout is working fine')

        localStorage.removeItem('token')
    }

    // console.log(userid)

    return (
        <>
            <nav className={headerClasses.join(' ')}>
                <div className='div d-flex'>
                    <div onClick={hidemenu} className={scrolled ? 'text-dark empty' : 'text-white empty'}>
                        <i class="fa-solid fa-bars" ></i>
                    </div>
                    <a className={scrolled ? 'text-dark text-decoration-none' : 'text-white text-decoration-none'} href="/shop">
                        Shop
                    </a>
                </div>
                <div className="headerlogo-div">
                    <a href='/'>
                        <img src={scrolled ? '/assets/images/logos/logoblack.webp' : '/assets/images/logos/logowhite.webp'} alt="Logo" className="logo" />
                    </a>
                </div>
                <div className='headercart-div'>
                    <Cart scroll={scrolled} />
                </div>
                <div className='sidemenu-header sidemenu-header-hide' ref={headerRef}>
                    <div className='text-start'>
                        <i class="fa-solid fa-xmark" onClick={hidemenu}></i>
                    </div>
                    <ul>
                        <Link onClick={hidemenu} to='/'>
                            <li>
                                Home
                            </li>
                        </Link>
                        <Link onClick={hidemenu} to='/shop'>
                            <li>
                                Shop
                            </li>
                        </Link>
                        <li>
                            {!userid ? <a href='/login'> <p> login</p> </a> : <a href=''> <p onClick={logout}>Logout</p> </a>}
                        </li>
                        {
                            userid ?
                                <Link to='/user-profile' onClick={hidemenu}>
                                    <li>
                                        User Profile
                                    </li>
                                </Link> : ''
                        }
                        <Link onClick={hidemenu} to="/track-order">
                            <li>
                                Track Your Order
                            </li>
                        </Link>
                    </ul>
                </div>
                <div className='header-overlay header-overlay-hide' onClick={hidemenu} ref={headeroverlayRef}>
                </div>
            </nav>
        </>
    )
}
